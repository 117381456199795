import BRANDS from "./brands"

export function checkIsAllsports (language) {
  return ['lt_lt', 'lt', 'lt_en', 'eu_en', 'en', 'cy_en'].indexOf(language) === -1
}
export function getLocaleByPathName (pathname, defaultLanguage) {
  const path = pathname ? pathname.substring(1) : ''
  const nextSlash = path.indexOf('/')
  const country = nextSlash >= 0 ? path.substring(0, nextSlash) : null
  const locale = country ?? defaultLanguage
  return locale
}

let brandByCountry;

export function brandConstansByLanguage(country){
  if (country ==="by") {
    brandByCountry = BRANDS.ALLSPORTS_BY
  }else if(country ==="ru" || country === "su_ru"){
    brandByCountry = BRANDS.ALLSPORTS_RU
  }else if(country ==="am"){
    brandByCountry = BRANDS.ALLSPORTS_AM
  }else if(country ==="ua"){
    brandByCountry = BRANDS.ALLSPORTS_UA
  }else if(country ==="lt_lt"){
    brandByCountry = BRANDS.SPORTBENEFIT_LT_LT
  }else if(country ==="lt_en" || country === "eu_en"){
    brandByCountry = BRANDS.SPORTBENEFIT_LT_EN
  } else if (country === 'cy_en') {
    brandByCountry = BRANDS.SPORTBENEFIT_CY_EN
  }
  return brandByCountry
}

export function checkIsAllsportsData (language) {
   if(['lt_lt', 'lt', 'lt_en', 'eu_en', 'en', 'cy_en'].indexOf(language) === -1){
    return BRANDS.ALLSPORTS
  }else{
    return BRANDS.SPORTBENEFIT
   }
}
