import React, { useRef, useState} from "react"
import './CountrySwitch.scss'

import LinkSwitch from "./LinkSwitch/LinkSwitch";
import OutsideClickHandler from 'react-outside-click-handler';
import COUNTRY from "../../constants";
import LOCALE from "../../locales"
import cn from 'classnames'
import {useTranslation} from "react-i18next";

const CountrySwitch = ({ visible }) => {
  const {t,i18n} = useTranslation()
  const [isClose, toggleIsClose] = useState(true)
  const switchSelect = useRef(null)

  const linkData = []

  const language = i18n.language
  const countryName = COUNTRY[i18n.language]
    ? t('countries.' + language)
    : language
  if (['ru', 'by', 'ua', 'am', 'su_ru'].indexOf(language) !== -1) {
    for (let key in LOCALE.BY) {
      let value = LOCALE.BY[key]
      let transKey = 'countries.' + value
      let text = t(transKey, '')
      let path = "/"

      if (!text || text === transKey) {
        continue
      }

      if (LOCALE.BY[key]) {
        path = `/${value}/`
      }

      const item = {
        title: text,
        path: path
      }

      linkData.push(item)
    }
  } else if (language === 'en') {

  } else {
    for (let key in LOCALE.LT) {
      let path = "/"

      if (LOCALE.LT[key]) {
        path = `/${LOCALE.LT[key]}/`
      }

      const item = {
        title: t('countries.' + key),
        path: path
      }

      linkData.push(item)
    }
  }

  function handleShowSelect(switchSelect) {
    toggleIsClose((isClose) => {
      return !isClose
    })
  }

  const handleClickOutside1 = (event) => {
    toggleIsClose(true)
  };

  return (
    <div className={cn("country-switch", {"show": visible})}>
      <OutsideClickHandler
        onOutsideClick={handleClickOutside1}
      >
      <button
        className="button-select"
        onClick={() => {
          handleShowSelect(switchSelect)
        }}
      >
        {countryName}
      </button>
      <div
        className={cn('switch-select',{'show':isClose})}
        ref={switchSelect}
      >
        {
          linkData.map((button, index) => {
            return (
              <LinkSwitch
                key={index}
                title={button.title}
                path={button.path}
              />
            )
          })
        }
      </div>
      </OutsideClickHandler>
    </div>

  )
}

export default CountrySwitch
