import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Logo from "../Logo/Logo"
import CountrySwitch from "../countrySwitch/CountrySwitch"
import classNames from "classnames"
import "./Header.scss"

import { useTranslation } from "react-i18next"

const Header = ({ links, baseUrl, country }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const { t } = useTranslation()

  return (
    <header className={classNames("header", { "menu-open": isMenuOpen })}>
      <Logo type={isMenuOpen ? "white" : ""} baseUrl={baseUrl} country={country} />

      <div className="list-wrapper">
        <ul>
          {links.map((link) => {
            const { text, href } = link
            return (
              <li key={href}>
                <Link
                  activeClassName="active"
                  state={{ dropdownLink: true }}
                  to={href}
                >
                  {t(text)}
                </Link>
              </li>
            )
          })}
        </ul>
        <CountrySwitch visible={isMenuOpen} />
      </div>

      <button
        className="menu"
        onClick={() => setMenuOpen(!isMenuOpen)}
        aria-label="menu"
      ></button>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
