 const COUNTRY = {
  BY: "by",
  AM: "am",
  RU: "ru",
  UA: "ua",
  eu_en: 'eu_en',
  lt_en: 'lt_en',
  cy_en: 'cy_en',
  lt_lt: 'lt_lt',
  EN: 'en',
  su_ru: 'su_ru'
}

export default COUNTRY
