import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import { checkIsAllsports, checkIsAllsportsData } from "../../domain_utils"

import "./Logo.scss"

const Logo = ({ type, baseUrl, country }) => {
  const {t,i18n} = useTranslation()
  const isAllsports = checkIsAllsports(country)
  const isWhite = type === 'white'

  const brandType = checkIsAllsportsData(country)
  const brand = brandType.brand

  return (
    <Link
      state={{ dropdownLink: true }}
      to={baseUrl ? baseUrl : "/"}
      className={classNames("logo", type)}
    >
      {isAllsports && !isWhite && (
        <StaticImage
          src="./images/logo.svg"
          alt="logo"
          placeholder="blurred"
          layout="fixed"
          width={32}
          height={34}
        />
      )}
      {!isAllsports && !isWhite && (
        <StaticImage
          src="./images/logo_sportbenefit.svg"
          alt="logo large"
          placeholder="blurred"
          layout="fixed"
          width={32}
          height={34}
        />
      )}
      {isAllsports && isWhite && (
        <StaticImage
          src="./images/logo-white.svg"
          alt="logo"
          placeholder="blurred"
          layout="fixed"
          width={32}
          height={34}
        />
      )}
      {!isAllsports && isWhite && (
        <StaticImage
          src="./images/logo_sportbenefit-white.svg"
          alt="logo large"
          placeholder="blurred"
          layout="fixed"
          width={32}
          height={34}
        />
      )}
      <span>{t('head.title', { brand })}</span>
    </Link>
  )
}

export default Logo
