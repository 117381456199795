/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect} from "react"
import PropTypes from "prop-types"

import Header from "./Header/Header"
import "./layout.scss"
import Footer from "./Footer/Footer"

import facebook from "../components/Footer/images/facebook-icon.png"
import vk from "../components/Footer/images/vk-icon.png"
import linkedin from "../components/Footer/images/icon-linkedin.svg"
import {useTranslation} from "react-i18next"

const footerAndHeaderData = {
  by: {
    headerLinks: [
      {
        text: "headerLinks.objects",
        href: "/by/objects/",
      },
      {
        text: "headerLinks.typesOfSubscriptions",
        href: "/by/prices/",
      },
      {
        text: "headerLinks.BecomeAPartner",
        href: "/by/affiliates/",
      },
      {
        text: "headerLinks.contact",
        href: "/by/contact/",
      },
    ],
    footerLinks: [
      {
        text: "footerLinks.forPrivateClients",
        href: "/by/prices/",
      },
      {
        text: "footerLinks.forCompanies",
        href: "/by/companies/",
      },
      {
        text: "footerLinks.forPartners",
        href: "/by/affiliates/",
      },
      {
        text: "footerLinks.objects",
        href: "/by/objects/",
      },
      {
        text: "footerLinks.contact",
        href: "/by/contact/",
      },
      {
        text: "footerLinks.blog",
        href: "/by/blog/",
      },
      {
        text: "footerLinks.licenseAgreement",
        href: "/by/license/",
      },
      {
        text: "footerLinks.allsportsCustomerRules",
        href: "/by/holder-app-rules/",
      },
    ],
    social: [
      {
        icon: facebook,
        href: "https://www.facebook.com/AllSports-355760931699171",
      },
      {
        icon: vk,
        href: "https://vk.com/public179007284",
      },
      {
        icon: linkedin,
        href: "https://www.linkedin.com/company/allsportsby",
      },
    ],
    baseUrl: "/by/"
  },
  am: {
    headerLinks: [
      {
        text: "headerLinks.objects",
        href: "/am/objects/",
      },
      {
        text: "headerLinks.typesOfSubscriptions",
        href: "/am/prices/",
      },
      {
        text: "headerLinks.BecomeAPartner",
        href: "/am/affiliates/",
      },
      {
        text: "headerLinks.contact",
        href: "/am/contact/",
      },
    ],
    footerLinks: [
      {
        text: "footerLinks.forPrivateClients",
        href: "/am/prices/",
      },
      {
        text: "footerLinks.forCompanies",
        href: "/am/companies/",
      },
      {
        text: "footerLinks.forPartners",
        href: "/am/affiliates/",
      },
      {
        text: "footerLinks.objects",
        href: "/am/objects/",
      },
      {
        text: "footerLinks.contact",
        href: "/am/contact/",
      },
      {
        text: "footerLinks.allsportsCustomerRules",
        href: "/am/holder-app-rules/",
      },
    ],
    social: [],
    baseUrl: "/am/"
  },
  ru: {
    headerLinks: [
      {
        text: "headerLinks.objects",
        href: "/ru/objects/",
      },
      {
        text: "headerLinks.typesOfSubscriptions",
        href: "/ru/prices/",
      },
      {
        text: "headerLinks.BecomeAPartner",
        href: "/ru/affiliates/",
      },
      {
        text: "headerLinks.contact",
        href: "/ru/contact/",
      },
    ],
    footerLinks: [
      {
        text: "footerLinks.forPrivateClients",
        href: "/ru/prices/",
      },
      {
        text: "footerLinks.forCompanies",
        href: "/ru/companies/",
      },
      {
        text: "footerLinks.forPartners",
        href: "/ru/affiliates/",
      },
      {
        text: "footerLinks.objects",
        href: "/ru/objects/",
      },
      {
        text: "footerLinks.contact",
        href: "/ru/contact/",
      },
      {
        text: "footerLinks.allsportsCustomerRules",
        href: "/ru/holder-app-rules/",
      },
    ],
    social: [],
    baseUrl: "/ru/"
  },
  ua: {
    headerLinks: [
    {
      text: "headerLinks.objects",
      href: "/ua/objects/",
    },
    {
      text: "headerLinks.typesOfSubscriptions",
      href: "/ua/prices/",
    },
    {
      text: "headerLinks.BecomeAPartner",
      href: "/ua/affiliates/",
    },
    {
      text: "headerLinks.contact",
      href: "/ua/contact/",
    },
  ],
  footerLinks: [
    {
      text: "footerLinks.forCompanies",
      href: "/ua/companies/",
    },
    {
      text: "footerLinks.forPartners",
      href: "/ua/affiliates/",
    },
    {
      text: "footerLinks.objects",
      href: "/ua/objects/",
    },
    {
      text: "footerLinks.contact",
      href: "/ua/contact/",
    },
  ],
    social: [],
    baseUrl: "/ua/"
  },
  lt_en: {
    headerLinks: [
      {
        text: "headerLinks.objects",
        href: "/lt_en/objects/",
      },
      {
        text: "headerLinks.typesOfSubscriptions",
        href: "/lt_en/prices/",
      },
      {
        text: "headerLinks.BecomeAPartner",
        href: "/lt_en/affiliates/",
      },
      {
        text: "headerLinks.contact",
        href: "/lt_en/contact/",
      },
    ],
    footerLinks: [
      {
        text: "footerLinks.forPartners",
        href: "/lt_en/affiliates/",
      },
      {
        text: "footerLinks.objects",
        href: "/lt_en/objects/",
      },
      {
        text: "footerLinks.contact",
        href: "/lt_en/contact/",
      },
    ],
    social: [],
    baseUrl: "/lt_en/"
  },
  lt_lt: {
    headerLinks: [
      {
        text: "headerLinks.objects",
        href: "/lt_lt/objects/",
      },
      {
        text: "headerLinks.typesOfSubscriptions",
        href: "/lt_lt/prices/",
      },
      {
        text: "headerLinks.BecomeAPartner",
        href: "/lt_lt/affiliates/",
      },
      {
        text: "headerLinks.contact",
        href: "/lt_lt/contact/",
      },
    ],
    footerLinks: [
      {
        text: "footerLinks.forPartners",
        href: "/lt_lt/affiliates/",
      },
      {
        text: "footerLinks.objects",
        href: "/lt_lt/objects/",
      },
      {
        text: "footerLinks.contact",
        href: "/lt_lt/contact/",
      },
    ],
    social: [],
    baseUrl: "/lt_lt/"
  },
  cy_en: {
    headerLinks: [
      {
        text: "headerLinks.objects",
        href: "/cy_en/objects/",
      },
      {
        text: "headerLinks.typesOfSubscriptions",
        href: "/cy_en/prices/",
      },
      {
        text: "headerLinks.BecomeAPartner",
        href: "/cy_en/affiliates/",
      },
      {
        text: "headerLinks.contact",
        href: "/cy_en/contact/",
      },
    ],
    footerLinks: [
      {
        text: "footerLinks.forPartners",
        href: "/cy_en/affiliates/",
      },
      {
        text: "footerLinks.objects",
        href: "/cy_en/objects/",
      },
      {
        text: "footerLinks.contact",
        href: "/cy_en/contact/",
      },
    ],
    social: [],
    baseUrl: "/cy_en/"
  },
  en: {
    headerLinks: [
    ],
    footerLinks: [
    ],
    social: [],
    baseUrl: "/en/"
  },
  su_ru: {
    headerLinks: [
    ],
    footerLinks: [
    ],
    social: [],
    baseUrl: "/su_ru/"
  }
}

const Layout = ({
  children,
  noFooter,
  hotLine,
  location,
  country,
}) => {

  const {t,i18n} = useTranslation()
  const path = location && location.pathname ? location.pathname.substring(1) : ''
  if (!country) {
    const nextSlash = path.indexOf('/')
    country = nextSlash >= 0 ? path.substring(0, nextSlash) : null
  }
  const locale = country ?? i18n.language ?? 'lt_en'
  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [i18n, locale])
  const data = footerAndHeaderData[locale]
  return (
    <>
    <div className="layout">
      <Header
        links={footerAndHeaderData[locale]
          ? footerAndHeaderData[locale].headerLinks
          : []}
        baseUrl={footerAndHeaderData[locale]
          ? footerAndHeaderData[locale].baseUrl
          : "/"}
          country={country}
      />
      <div>
        <main>{children}</main>
      </div>
      {noFooter ||
          <Footer
            country={country}
            hotLine={hotLine}
            links={data?.footerLinks ?? []}
            social={data?.social ?? []}
            baseUrl={data?.baseUrl ?? '/su_ru/'}
            emailLink={{ title: t("contacts.links.link-1.title"), href: t("contacts.links.link-1.href") }}
          />
        }
    </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
