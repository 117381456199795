const BRANDS = {
    ALLSPORTS: {
        aggregator: 'ООО ОЛЛСПОРТС',
        brand: 'Allsports',
        appTitle: 'Allsports',
        author: 'allsportsby',
        headerLinks: 'Allsports+',
        address: '220030, г.Минск, ул.Зыбицкая, д.9, пом.39-41',
    },
    ALLSPORTS_AM:{
        legalAddress:"«ՕԼԼՍՊՈՐՏՍ» ՍՊԸ",
        address:"",
    },
    ALLSPORTS_BY:{
        legalAddress: "ООО “ОЛЛСПОРТС” УНП 193190172, зарегистрирован Минским Горисполкомом 10\n        Января 2019 года. Республика Беларусь, г.Минск, ул.Зыбицкая, д.9, пом.39-41",
        address: '220030, г.Минск, ул.Зыбицкая, д.9, пом.39-41',
    },
    ALLSPORTS_RU:{
        legalAddress: "ООО «ОЛЛСПОРТС» 125438,\nГ.МОСКВА,\nВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ ГОЛОВИНСКИЙ,\nУЛ АВТОМОТОРНАЯ,\nД. 1/3 СТР. 2",
        address: "ООО «ОЛЛСПОРТС» 125438,\nГ.МОСКВА,\nВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ ГОЛОВИНСКИЙ,\nУЛ АВТОМОТОРНАЯ,\nД. 1/3 СТР. 2",
    },
    ALLSPORTS_UA:{
        legalAddress:"Address: Volodymyrska St, 52/17 (4th floor), Kyiv, 01030, Room F09",
        address: "Вул. Володимирська, 52/17 (4 поверх), Київ, 01030, кімната F09",
    },
    SPORTBENEFIT: {
        aggregator: 'SportBenefit LLC',
        brand: 'SportBenefit',
        appTitle: 'Sportbenefit.eu',
        author: 'UAB Sport benefit 305642474',
        headerLinks: 'SportBenefit+',
        contactMail:'info@sportbenefit.eu',
    },
    SPORTBENEFIT_LT_EN:{
        legalAddress: "Paneriu g. 39 LT-03229, Vilnius, Lithuania",
        address: "Naujininkų g. 7-1, Vilnius, Lithuania",
    },
    SPORTBENEFIT_LT_LT:{
        legalAddress: "Paneriu g. 39 LT-03229, Vilnius, Lithuania",
        address: "Naujininkų g. 7-1, Vilnius, Lietuva",
    },
    SPORTBENEFIT_CY_EN:{
      aggregator: 'AG & FFW ALLSPORTS SOFTWARE LTD',
      legalAddress: "Chrysanthou Mylona, Panayides Building\n" +
        "4th Floor, Office 1, 3030 Limassol, Cyprus",
      address: "P.O. Box: 53806, 3318 Limassol, Cyprus",
    },
}

export default BRANDS
