const LOCALE = {
  LT: {
    lt_en: 'lt_en',
    lt_lt: 'lt_lt',
    cy_en: 'cy_en'
  },
  BY: {
    BY: "by",
    AM: "am",
    RU: "ru"
  }
}

export default LOCALE
